import React from 'react';
import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';

const TableHeader = ({ judges, events, final }) => {
    //console.log(judges)
    let r = events.competition.competition.grade.rounds;
    let panelType = events.competition.competition.grade.scoring.judge_rotation_type;
    let judgeCount = events.competition.competition.grade.judges;
    let rotating = panelType === "ROTATING";
    let set = panelType === "SET";
    let split = panelType === "SPLIT";
    let teams = events.competition.competition.grade.type === "teams";
    let finalJudgeNum = judgeCount + 1;
    let finalJudgeIndex = (judgeCount * 2) -1;  
    let recall = events.competition.competition.grade.recallType !== 'NONE';
    let showIp = events.competition.competition.grade.scoring.showIp || false;
    let nq = events.competition.competition.grade.nationalQualifier;
    let wq = events.competition.competition.grade.worldQualifier;


  return (
    <View style={styles.wrap}>
      <View style={[styles.titleWrap, { borderLeft: "none" }]}>
        <Text style={styles.title}>Dancer Number</Text>
      </View>
      {teams ? 
        <View style={[styles.titleWrap, { width: "10%" }]}>
          <Text style={styles.title}>Team</Text>
        </View>
          :
        <View style={[styles.titleWrap, { width: "10%" }]}>
          <Text style={styles.title}>Dancer Name</Text>
        </View>
        }
      <View style={[styles.titleWrap, { width: "10%" }]}>
        <Text style={styles.title}>School</Text>
      </View>
      {rotating ? 
        <View style={[styles.titleWrap, { width: "6%" }]}>
            <Text style={styles.title}>R1</Text>
            <Text style={styles.smallerFont}>Judge 1 Score/IP</Text>
            <Text style={styles.judgeName}>{judges[0]}</Text>
          </View>
        : null}
      {rotating && judgeCount >= 2 ? 
        <View style={[styles.titleWrap, { width: "6%" }]}>
            <Text style={styles.title}>R1</Text>
            <Text style={styles.smallerFont}>Judge 2 Score/IP</Text>
            <Text style={styles.judgeName}>{judges[1]}</Text>
          </View>
        : null}
      {rotating && judgeCount >= 3 ? 
        <View style={[styles.titleWrap, { width: "6%" }]}>
            <Text style={styles.title}>R1</Text>
            <Text style={styles.smallerFont}>Judge 3 Score/IP</Text>
            <Text style={styles.judgeName}>{judges[2]}</Text>
          </View>
        : null}
      {rotating ? 
        <View style={[styles.titleWrap, {backgroundColor: "#FFE08C", width: "4%" }]}>
          <Text style={styles.title}>Total IP R1</Text>
        </View>
        : null}
      {rotating ? 
        <View style={[styles.titleWrap, {backgroundColor: "#FFD45E", width: "4%" }]}>
          <Text style={styles.title}>Rank after R1</Text>
        </View>
        : null}
      {rotating ? 
        <View style={[styles.titleWrap, { width: "6%" }]}>
            <Text style={styles.title}>R2</Text>
            <Text style={styles.smallerFont}>Judge 1 Score/IP</Text>
            <Text style={styles.judgeName}>{judges[3]}</Text>
          </View>
        : null}
      {rotating && judgeCount >= 2 ? 
        <View style={[styles.titleWrap, { width: "6%" }]}>
            <Text style={styles.title}>R2</Text>
            <Text style={styles.smallerFont}>Judge 2 Score/IP</Text>
            <Text style={styles.judgeName}>{judges[4]}</Text>
          </View>
        : null}
      {rotating && judgeCount >= 3 ? 
        <View style={[styles.titleWrap, { width: "6%" }]}>
            <Text style={styles.title}>R2</Text>
            <Text style={styles.smallerFont}>Judge 3 Score/IP</Text>
            <Text style={styles.judgeName}>{judges[5]}</Text>
          </View>
        : null}
      {rotating ? 
        <View style={[styles.titleWrap, {backgroundColor: "#DBF7CD", width: "4%" }]}>
          <Text style={styles.title}>Total IP R2</Text>
        </View>
        : null}
      {rotating ? 
        <View style={[styles.titleWrap, {backgroundColor: "#C5F7AD", width: "4%" }]}>
          <Text style={styles.title}>Total IP R1 + R2</Text>
        </View>
        : null}
      {rotating ? 
        <View style={[styles.titleWrap, {backgroundColor: "#B6FF94", width: "4%" }]}>
          <Text style={styles.title}>Rank after R2</Text>
        </View>
        : null}
      {rotating ? 
        <View style={[styles.titleWrap, {backgroundColor: "#C7EBFC", width: "4%" }]}>
          <Text style={styles.title}>Recall Status (Y/N)</Text>
        </View>
        : null}
      {rotating ? 
        <View style={[styles.titleWrap, { width: "6%" }]}>
            <Text style={styles.title}>R3</Text>
            <Text style={styles.smallerFont}>Judge 1 Score/IP</Text>
            <Text style={styles.judgeName}>{judges[6]}</Text>
          </View>
        : null}
      {rotating && judgeCount >= 2 ? 
        <View style={[styles.titleWrap, { width: "6%" }]}>
            <Text style={styles.title}>R3</Text>
            <Text style={styles.smallerFont}>Judge 2 Score/IP</Text>
            <Text style={styles.judgeName}>{judges[7]}</Text>
          </View>
        : null}
      {rotating && judgeCount >= 3 ? 
        <View style={[styles.titleWrap, { width: "6%" }]}>
            <Text style={styles.title}>R3</Text>
            <Text style={styles.smallerFont}>Judge 3 Score/IP</Text>
            <Text style={styles.judgeName}>{judges[8]}</Text>
          </View>
        : null}
      {rotating ? 
        <View style={[styles.titleWrap, {backgroundColor: "#FFCCE0", width: "4%" }]}>
          <Text style={styles.title}>Total IP R3</Text>
        </View>
        : null}
      {rotating ? 
        <View style={[styles.titleWrap, {backgroundColor: "#F7A8C7", width: "4.3%" }]}>
          <Text style={styles.title}>Total IP All Rounds</Text>
        </View>
        : null}
      {rotating ? 
        <View style={[styles.titleWrap, {backgroundColor: "#FF6EA5", width: "4%" }]}>
          <Text style={styles.title}>Final Rank</Text>
        </View>
        : null}
      {rotating ? 
        <View style={[styles.titleWrap, { width: "4%" }]}>
          <Text style={styles.title}>WQ Y/N</Text>
        </View>
        : null}
      {rotating ? 
        <View style={[styles.titleWrap, { width: "4%" }]}>
          <Text style={styles.title}>NQ Y/N</Text>
        </View>
        : null}
        {set && !final ?
          <View style={[styles.titleWrap, { width: "4%" }]}>
            <Text style={styles.title}>Judge 1</Text>
            <Text style={styles.smallerFont}>R1 Score</Text>
            <Text style={styles.judgeName}>{judges[0]}</Text>
          </View>
        : null}

        {set && !final && r >= 2 ?
            <View style={[styles.titleWrap, { width: "4%" }]}>
                <Text style={styles.title}>Judge 1</Text>
                <Text style={styles.smallerFont}>R2 Score</Text>
                <Text style={styles.judgeName}>{judges[0]}</Text>
            </View>
        : null}

        {set && !final && r >= 3 ?
            <View style={[styles.titleWrap, { width: "4%" }]}>
                <Text style={styles.title}>Judge 1</Text>
                <Text style={styles.smallerFont}>R3 Score</Text>
                <Text style={styles.judgeName}>{judges[0]}</Text>
            </View>
        : null}

        {split && !final && r >= 2 ?
          <View style={[styles.titleWrap, { width: "6%" }]}>
            <Text style={styles.title}>Judge 1</Text>
            <Text style={styles.smallerFont}>R1/R2 Score</Text>
            <Text style={styles.judgeName}>{judges[0]}</Text>
          </View>
        : null}

        {set && !final && r > 1 ?
              <View style={[styles.titleWrap, { backgroundColor: "#FFE08C", width: "4%" }]}>
              <Text style={styles.title}>Judge 1 Total Score</Text>
            </View>
        : null}

        {set && !final && showIp && judgeCount > 1 ?
              <View style={[styles.titleWrap, { backgroundColor: "#FFD45E", width: "4%" }]}>
              <Text style={styles.title}>Judge 1 IP</Text>
            </View>
        : null}

        {split && !final && judgeCount >= 2 ?
              <View style={[styles.titleWrap, { backgroundColor: "#FFE08C", width: "4%" }]}>
              <Text style={styles.title}>Judge 1 Subtotal</Text>
            </View>
        : null}

        {split && !final && judgeCount >= 2 ?
              <View style={[styles.titleWrap, { backgroundColor: "#FFD45E", width: "6%" }]}>
              <Text style={styles.title}>Judge 1 Rank/IP</Text>
            </View>
        : null}

      {set && !final && judgeCount >= 2?
          <View style={[styles.titleWrap, { width: "4%" }]}>
            <Text style={styles.title}>Judge 2</Text>
            <Text style={styles.smallerFont}>R1 Score</Text>
            <Text style={styles.judgeName}>{judges[1]}</Text>
          </View>
        : null}

        {set && !final && judgeCount >= 2 && r >= 2 ?
            <View style={[styles.titleWrap, { width: "4%" }]}>
                <Text style={styles.title}>Judge 2</Text>
                <Text style={styles.smallerFont}>R2 Score</Text>
                <Text style={styles.judgeName}>{judges[1]}</Text>
            </View>
        : null}

        {set && !final && judgeCount >= 2 && r >= 3 ?
            <View style={[styles.titleWrap, { width: "4%" }]}>
                <Text style={styles.title}>Judge 2</Text>
                <Text style={styles.smallerFont}>R3 Score</Text>
                <Text style={styles.judgeName}>{judges[1]}</Text>
            </View>
        : null}

        {split && !final && judgeCount >= 2 && r >= 2 ?
          <View style={[styles.titleWrap, { width: "6%" }]}>
            <Text style={styles.title}>Judge 2</Text>
            <Text style={styles.smallerFont}>R1/R2 Score</Text>
            <Text style={styles.judgeName}>{judges[1]}</Text>
          </View>
        : null}

        {set && !final && judgeCount >= 2 && r > 1 ?
              <View style={[styles.titleWrap, { backgroundColor: "#DBF7CD", width: "4%" }]}>
              <Text style={styles.title}>Judge 2 Total Score</Text>
            </View>
        : null}

        {set && !final && showIp && judgeCount >= 2 ?
              <View style={[styles.titleWrap, { backgroundColor: "#C5F7AD", width: "4%" }]}>
              <Text style={styles.title}>Judge 2 IP</Text>
            </View>
        : null}

        {split && !final && judgeCount >= 2 ?
              <View style={[styles.titleWrap, { backgroundColor: "#DBF7CD", width: "4%" }]}>
              <Text style={styles.title}>Judge 2 Subtotal</Text>
            </View>
        : null}

        {split && !final && judgeCount >= 2 ?
              <View style={[styles.titleWrap, { backgroundColor: "#C5F7AD", width: "6%" }]}>
              <Text style={styles.title}>Judge 2 Rank/IP</Text>
            </View>
        : null}

        {set && !final && judgeCount >= 3?
          <View style={[styles.titleWrap, { width: "4%" }]}>
            <Text style={styles.title}>Judge 3</Text>
            <Text style={styles.smallerFont}>R1 Score</Text>
            <Text style={styles.judgeName}>{judges[2]}</Text>
          </View>
        : null}

        {set && !final && judgeCount >= 3 && r >= 2 ?
            <View style={[styles.titleWrap, { width: "4%" }]}>
                <Text style={styles.title}>Judge 3</Text>
                <Text style={styles.smallerFont}>R2 Score</Text>
                <Text style={styles.judgeName}>{judges[2]}</Text>
            </View>
        : null}

        {set && !final && judgeCount >= 3 && r >= 3 ?
            <View style={[styles.titleWrap, { width: "4%" }]}>
                <Text style={styles.title}>Judge 3</Text>
                <Text style={styles.smallerFont}>R3 Score</Text>
                <Text style={styles.judgeName}>{judges[2]}</Text>
            </View>
        : null}

        {split && !final && judgeCount >= 3 && r >= 2 ?
          <View style={[styles.titleWrap, { width: "6%" }]}>
            <Text style={styles.title}>Judge 3</Text>
            <Text style={styles.smallerFont}>R1/R2 Score</Text>
            <Text style={styles.judgeName}>{judges[2]}</Text>
          </View>
        : null}

        {set && !final && judgeCount >= 3 && r > 1 ?
              <View style={[styles.titleWrap, { backgroundColor: "#D3F5F1", width: "4%" }]}>
              <Text style={styles.title}>Judge 3 Total Score</Text>
            </View>
        : null}

        {set && !final && showIp && judgeCount >= 3 ?
              <View style={[styles.titleWrap, { backgroundColor: "#B9FAF3", width: "4%" }]}>
              <Text style={styles.title}>Judge 3 IP</Text>
            </View>
        : null}

        {split && !final && judgeCount >= 3 ?
              <View style={[styles.titleWrap, { backgroundColor: "#D3F5F1", width: "4%" }]}>
              <Text style={styles.title}>Judge 3 Subtotal</Text>
            </View>
        : null}

        {split && !final && judgeCount >= 3 ?
              <View style={[styles.titleWrap, { backgroundColor: "#B9FAF3", width: "6%" }]}>
              <Text style={styles.title}>Judge 3 Rank/IP</Text>
            </View>
        : null}

        {set && !final && judgeCount >= 4?
          <View style={[styles.titleWrap, { width: "4%" }]}>
            <Text style={styles.title}>Judge 4</Text>
            <Text style={styles.smallerFont}>R1 Score</Text>
            <Text style={styles.judgeName}>{judges[3]}</Text>
          </View>
        : null}

        {set && !final && judgeCount >= 4 && r >= 2 ?
            <View style={[styles.titleWrap, { width: "4%" }]}>
                <Text style={styles.title}>Judge 4</Text>
                <Text style={styles.smallerFont}>R2 Score</Text>
                <Text style={styles.judgeName}>{judges[3]}</Text>
            </View>
        : null}

        {set && !final && judgeCount >= 4 && r >= 3 ?
            <View style={[styles.titleWrap, { width: "4%" }]}>
                <Text style={styles.title}>Judge 4</Text>
                <Text style={styles.smallerFont}>R3 Score</Text>
                <Text style={styles.judgeName}>{judges[3]}</Text>
            </View>
        : null}

        {split && !final && judgeCount >= 4 && r >= 2 ?
          <View style={[styles.titleWrap, { width: "6%" }]}>
            <Text style={styles.title}>Judge 4</Text>
            <Text style={styles.smallerFont}>R1/R2 Score</Text>
            <Text style={styles.judgeName}>{judges[3]}</Text>
          </View>
        : null}

        {set && !final && judgeCount >= 4 && r > 1 ?
              <View style={[styles.titleWrap, { backgroundColor: "#C7EBFC", width: "4%" }]}>
              <Text style={styles.title}>Judge 4 Total Score</Text>
            </View>
        : null}

        {set && !final && showIp && judgeCount >= 4 ?
              <View style={[styles.titleWrap, { backgroundColor: "#ABDEF7", width: "4%" }]}>
              <Text style={styles.title}>Judge 4 IP</Text>
            </View>
        : null}

        {split && !final && judgeCount >= 4 ?
              <View style={[styles.titleWrap, { backgroundColor: "#C7EBFC", width: "4%" }]}>
              <Text style={styles.title}>Judge 4 Subtotal</Text>
            </View>
        : null}

        {split && !final && judgeCount >= 4 ?
              <View style={[styles.titleWrap, { backgroundColor: "#ABDEF7", width: "6%" }]}>
              <Text style={styles.title}>Judge 4 Rank/IP</Text>
            </View>
        : null}

        {set && !final && judgeCount >= 5?
          <View style={[styles.titleWrap, { width: "4%" }]}>
            <Text style={styles.title}>Judge 5</Text>
            <Text style={styles.smallerFont}>R1 Score</Text>
            <Text style={styles.judgeName}>{judges[4]}</Text>
          </View>
        : null}

        {set && !final && judgeCount >= 5 && r >= 2 ?
            <View style={[styles.titleWrap, { width: "4%" }]}>
                <Text style={styles.title}>Judge 5</Text>
                <Text style={styles.smallerFont}>R2 Score</Text>
                <Text style={styles.judgeName}>{judges[4]}</Text>
            </View>
        : null}

        {set && !final && judgeCount >= 5 && r >= 3 ?
            <View style={[styles.titleWrap, { width: "4%" }]}>
                <Text style={styles.title}>Judge 5</Text>
                <Text style={styles.smallerFont}>R3 Score</Text>
                <Text style={styles.judgeName}>{judges[4]}</Text>
            </View>
        : null}

        {split && !final && judgeCount >= 5 && r >= 2 ?
          <View style={[styles.titleWrap, { width: "6%" }]}>
            <Text style={styles.title}>Judge 5</Text>
            <Text style={styles.smallerFont}>R1/R2 Score</Text>
            <Text style={styles.judgeName}>{judges[4]}</Text>
          </View>
        : null}

        {set && !final && judgeCount >= 5 && r > 1 ?
              <View style={[styles.titleWrap, { backgroundColor: "#ECD4FA", width: "4%" }]}>
              <Text style={styles.title}>Judge 5 Total Score</Text>
            </View>
        : null}

        {set && !final && showIp && judgeCount >= 5 ?
              <View style={[styles.titleWrap, { backgroundColor: "#DEB2F7", width: "4%" }]}>
              <Text style={styles.title}>Judge 5 IP</Text>
            </View>
        : null}

        {split && !final && judgeCount >= 5 ?
              <View style={[styles.titleWrap, { backgroundColor: "#ECD4FA", width: "4%" }]}>
              <Text style={styles.title}>Judge 5 Subtotal</Text>
            </View>
        : null}

        {split && !final && judgeCount >= 5 ?
              <View style={[styles.titleWrap, { backgroundColor: "#DEB2F7", width: "6%" }]}>
              <Text style={styles.title}>Judge 5 Rank/IP</Text>
            </View>
        : null}


      {set && !final && r >= 2 && showIp ? 
        <View style={[styles.titleWrap, { backgroundColor: "#F7E9F1", width: "5%" }]}>
            <Text style={styles.title}>Total IP</Text>
        </View>
        : null }
      {split && !final ?
        <View style={[styles.titleWrap, { backgroundColor: "#FFCCE0", width: "5%" }]}>
        <Text style={styles.title}>Total IP 2 Rounds</Text>
        </View>
        : null
      }
      {set && !final ? 
        <View style={[styles.titleWrap, { backgroundColor: "#FFCCE0", width: "4%" }]}>
          <Text style={styles.title}>Final Rank</Text>
        </View>
        : null }
      {split && !final ?
        <View style={[styles.titleWrap, { backgroundColor: "#F7A8C7", width: "3.3%" }]}>
          <Text style={styles.title}>2 Round Rank</Text>
        </View>
        : null
      }
      {set && !final && r >= 2 ? 
        <View style={[styles.titleWrap, { backgroundColor: "#F7A8C7", width: "4%" }]}>
          <Text style={styles.title}>Placed Y/N</Text>
        </View> : null }

      {set && nq ? 
      <View style={[styles.titleWrap, { backgroundColor: "#FF6EA5", width: "4%" }]}>
        <Text style={styles.title}>NQ Y/N</Text>
      </View> : null}
      {set && wq ? 
      <View style={[styles.titleWrap, { backgroundColor: "#FC1C97", width: "4%" }]}>
        <Text style={styles.title}>WQ Y/N</Text>
      </View> : null}

      {split && !final && recall ?
        <View style={[styles.titleWrap, { backgroundColor: "#FC1C97", width: "3.3%" }]}>
          <Text style={styles.title}>Recall Y/N</Text>
        </View> : null
        }

      {final ? 
        <View style={[styles.titleWrap, { width: "4%" }]}>
            <Text style={styles.title}>Judge {finalJudgeNum}</Text>
            <Text style={styles.smallerFont}>R3 Score</Text>
            <Text style={styles.judgeName}>{judges[finalJudgeIndex]}</Text>
        </View>  
        : null
      }

      {final ?
          <View style={[styles.titleWrap, { backgroundColor: "#FFE08C", width: "6%" }]}>
          <Text style={styles.title}>Judge {finalJudgeNum} Rank/IP</Text>
        </View>
      : null}

      {final && judgeCount >= 2 ? 
        <View style={[styles.titleWrap, { width: "4%" }]}>
            <Text style={styles.title}>Judge {finalJudgeNum + 1}</Text>
            <Text style={styles.smallerFont}>R3 Score</Text>
            <Text style={styles.judgeName}>{judges[finalJudgeIndex + 1]}</Text>
        </View>  
        : null
      }

      {final && judgeCount >= 2 ?
          <View style={[styles.titleWrap, { backgroundColor: "#DBF7CD", width: "6%" }]}>
          <Text style={styles.title}>Judge {finalJudgeNum + 1} Rank/IP</Text>
        </View>
      : null}

      {final && judgeCount >= 3 ? 
        <View style={[styles.titleWrap, { width: "4%" }]}>
            <Text style={styles.title}>Judge {finalJudgeNum + 2}</Text>
            <Text style={styles.smallerFont}>R3 Score</Text>
            <Text style={styles.judgeName}>{judges[finalJudgeIndex + 2]}</Text>
        </View>  
        : null
      }

      {final && judgeCount >= 3 ?
          <View style={[styles.titleWrap, { backgroundColor: "#D3F5F1", width: "6%" }]}>
          <Text style={styles.title}>Judge {finalJudgeNum + 2} Rank/IP</Text>
        </View>
      : null}

      {final && judgeCount >= 4 ? 
        <View style={[styles.titleWrap, { width: "4%" }]}>
            <Text style={styles.title}>Judge {finalJudgeNum + 3}</Text>
            <Text style={styles.smallerFont}>R3 Score</Text>
            <Text style={styles.judgeName}>{judges[finalJudgeIndex + 3]}</Text>
        </View>  
        : null
      }

      {final && judgeCount >= 4 ?
          <View style={[styles.titleWrap, { backgroundColor: "#C7EBFC", width: "6%" }]}>
          <Text style={styles.title}>Judge {finalJudgeNum + 3} Rank/IP</Text>
        </View>
      : null}

      {final && judgeCount >= 5 ? 
        <View style={[styles.titleWrap, { width: "4%" }]}>
            <Text style={styles.title}>Judge {finalJudgeNum + 4}</Text>
            <Text style={styles.smallerFont}>R3 Score</Text>
            <Text style={styles.judgeName}>{judges[finalJudgeIndex + 4]}</Text>
        </View>  
        : null
      }

      {final && judgeCount >= 5 ?
          <View style={[styles.titleWrap, { backgroundColor: "#ECD4FA", width: "6%" }]}>
          <Text style={styles.title}>Judge {finalJudgeNum + 4} Rank/IP</Text>
        </View>
      : null}

      {final ? 
        <View style={[styles.titleWrap, { backgroundColor: "#F7E9F1", width: "5%" }]}>
            <Text style={styles.title}>Total IP R1 & R2</Text>
        </View>
        : null }
      {final ?
        <View style={[styles.titleWrap, { backgroundColor: "#FFCCE0", width: "5%" }]}>
        <Text style={styles.title}>Total IP R3</Text>
        </View>
        : null
      }
      {final ? 
        <View style={[styles.titleWrap, { backgroundColor: "#F7A8C7", width: "6%" }]}>
          <Text style={styles.title}>Total IP All Rounds</Text>
        </View>
        : null }
      {final ?
        <View style={[styles.titleWrap, { backgroundColor: "#FF6EA5", width: "4%" }]}>
          <Text style={styles.title}>Final Rank</Text>
        </View>
        : null
      }
      {final ? 
        <View style={[styles.titleWrap, { backgroundColor: "#FC1C97", width: "4%" }]}>
          <Text style={styles.title}>Placed Y/N</Text>
        </View> : null }

    </View>
  )
}

Font.register({
  family: "Roboto",
  src:
    "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf"
});


// Create styles
const styles = StyleSheet.create({
  wrap: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  titleWrap: {
    borderBottom: "2px solid black",
    borderRight: "1px solid black",
    borderTop: "2px solid black",
    display: "flex",
    flexDirection: "column",
    height: "auto",
    textAlign: "center",
    width: "5%",
  },
  title: {
    //fontFamily: "Roboto",
    fontSize: 8,
    fontWeight: 900,
    padding: 0,
    paddingBottom: "3px",
  },
  smallerFont: {
    fontSize: 6,
    paddingBottom: "10px",
  },
  judgeName: {
    borderTop: "1px solid black",
    fontSize: 6,
    fontWeight: 900,
    padding: "5px 0",
  },
});

export default TableHeader;