/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 11/1/2021
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../store/actions/table';
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import {Formik} from "formik";
import * as yup from "yup";
import * as eventActions from "../../../store/actions/events";
import {Checkmark, OpenOutline} from "react-ionicons";
import {FormControl} from "react-bootstrap";
import PageLoader from "../../Common/PageLoader";

const schema = yup.object({
    email: yup.string().email("Please enter a valid email address").required("This field is required"),
    password: yup.string().min(8, 'Password needs to be at least 8 characters long'),
    password2: yup.mixed().test('match', 'Password does not match', function (password2) {
        return password2 === this.parent.password
    })
});

const ROTATION_TYPE = {
    SET: "SET",
    SPLIT: "SPLIT",
    ROTATING: "ROTATING"
}

const RECALL_TYPE = {
    NONE: "NONE",
    ALL: "ALL",
    PERCENT: "PERCENT"

}

const COLUMN_COLORS = [
    [
        "#FFE08C",
        "#FFD45E",
        "#F7BB16"
    ],
    [
        "#DBF7CD",
        "#C5F7AD",
        "#B6FF94"
    ],
    [
        "#D3F5F1",
        "#B9FAF3",
        "#90F5EB"
    ],
    [
        "#C7EBFC",
        "#ABDEF7",
        "#6EC5F0"
    ],
    [
        "#ECD4FA",
        "#DEB2F7",
        "#D797FC"
    ]
]

const FINAL_COLUMN_COLORS = [
    "#F7E9F1",
    "#FFCCE0",
    "#F7A8C7",
    "#FF6EA5",
    "#FC1C97"
];


class TabulatorOverviewTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locked: [
                false,
                false,
                false,
                false
            ],
            items: []
        }
    }

    componentDidMount(props) {
        if (this.props.table.view !== "") {
            //this.props.getTableData("", 0, 999, {number: 1}, this.props.auth.token._id, 'add');
        }
    }

    componentDidUpdate(props, state) {
        if ((this.props.table.data.length !== this.state.items.length || this.props.table.updateCount > props.table.updateCount || this.props.table.data.length !== this.state.items.length) && this.props.table.view === "") {
            this.setState({items: this.props.table.data});

        }
    }

    emptyJudge = {
        name: 'Not',
        lname: 'Selected',
        email: ''
    }

    toggleLock = (val) => {
        let tab = this.props.events.competition.tabulation;
        tab["round" + this.props.match.params.round + "Locked"] = !tab["round" + this.props.match.params.round + "Locked"];
        this.props.updateTabulationObject(tab, this.props.auth.token.token._id);
    }

    render() {

        console.log("Render invoked");

        const entriesMain = this.props.events.competition.entries;
        const rounds = this.props.events.competition.competition.grade.rounds;
        const judgeCount = this.props.events.competition.competition.grade.judges;
        const panelType = this.props.events.competition.competition.grade.scoring.judge_rotation_type;
        const judgeCountArray = this.props.events.competition.competition.grade.scoring.judge_count;
        const teams = this.props.events.competition.competition.grade.type.toLowerCase().includes("teams");
        const final = this.props.match.params.round === 'final';
        const isLocked = this.props.locked;
        let rotating  = panelType === 'ROTATING';

        console.log("final", final);

        let dancerHeader = teams ? "Team" : "Dancer";
        // Headers always start with Number, Name, and School
        let headers = [{
            showJudgeName: false,
            name: dancerHeader + " Number"
        },
        {
            showJudgeName: false,
            name: dancerHeader + " Name"
        },
        {
            showJudgeName: false,
            name: "School"
        }];

        let fieldArray = [];

        let colGroupArray = ["<col />", "<col />", "<col />"];

        let addJudgeHeaders = (judgeName, roundNum,  roundCount, judgeIdNum, judgeIndex) => {

            // Get the total column colors for this judge by index
            let colorsIndex = judgeIndex % COLUMN_COLORS.length;
            let judgeColorsArray = COLUMN_COLORS[colorsIndex];

            // Add main judge score fields, applies to all judge rotation types
            for(let i = roundNum; i <= roundCount; i++) {
                headers.push({
                    showJudgeName: true,
                    judgeIdNum: judgeIdNum,
                    name: "R" + i + " Score",
                    judgeName: judgeName
                });
                fieldArray.push({
                    roundNum: i,
                    judgeIndex: judgeIndex,
                    name: "round" + i + "Score"
                })
                colGroupArray.push("<col />");
            }

            switch (panelType) {
                case ROTATION_TYPE.SET: 
                    // Add judge total fields
                    headers.push({
                        showJudgeName: false,
                        name: "Judge " + judgeIdNum + " Total Score"
                    });
                    fieldArray.push({
                        roundNum: roundNum,
                        judgeIndex: judgeIndex,
                        name: "judgeTotalScore"
                    })
                    colGroupArray.push("<col bgcolor=\"" + judgeColorsArray[0] + "\">");

                    if (judgeCountArray[roundNum - 1] > 1) {
                        // We don't need Judge Rank or IP if there is only one judge

                        // Judge Rank
                        headers.push({
                            showJudgeName: false,
                            name: "Judge " + judgeIdNum + " Rank"
                        });
                        fieldArray.push({
                            roundNum: roundNum,
                            judgeIndex: judgeIndex,
                            name: "judgeFinalRank",
                            tiedField: "judgeTied"
                        })
                        colGroupArray.push("<col bgcolor=\"" + judgeColorsArray[1] + "\">");

                        // Judge IP
                        headers.push({
                            showJudgeName: false,
                            name: "Judge " + judgeIdNum + " IP"
                        });
                        fieldArray.push({
                            roundNum: roundNum,
                            judgeIndex: judgeIndex,
                            name: "judgeFinalIp"
                        })
                        colGroupArray.push("<col bgcolor=\"" + judgeColorsArray[2] + "\">");
                    }
    
                    break;
                case ROTATION_TYPE.SPLIT: 
                    if (final) {
                        headers.push({
                            showJudgeName: false,
                            name: "Judge " + judgeIdNum + " Round" + roundNum + " Rank"
                        });
                        fieldArray.push({
                            roundNum: roundNum,
                            judgeIndex: judgeIndex,
                            name: "round3Rank",
                            tiedField: "round3Tied"
                        })
                        colGroupArray.push("<col bgcolor=\"" + judgeColorsArray[0] + "\">");

                        headers.push({
                            showJudgeName: false,
                            name: "Judge " + judgeIdNum + " Round" + roundNum + " IP"
                        });
                        fieldArray.push({
                            roundNum: roundNum,
                            judgeIndex: judgeIndex,
                            name: "round3Ip"
                        })
                        colGroupArray.push("<col bgcolor=\"" + judgeColorsArray[1] + "\">");
                    } else {
                        headers.push({
                            showJudgeName: false,
                            name: "Judge " + judgeIdNum + " Subtotal Raw Score (2 Rounds)"
                        });
                        fieldArray.push({
                            roundNum: roundNum,
                            judgeIndex: judgeIndex,
                            name: "judgeTotalScore"
                        })
                        colGroupArray.push("<col bgcolor=\"" + judgeColorsArray[0] + "\">");

                        headers.push({
                            showJudgeName: false,
                            name: "Judge " + judgeIdNum + " Rank (2 Rounds)"
                        });
                        fieldArray.push({
                            roundNum: roundNum,
                            judgeIndex: judgeIndex,
                            name: "judgeFinalRank",
                            tiedField: "judgeTied"
                        })
                        colGroupArray.push("<col bgcolor=\"" + judgeColorsArray[1] + "\">");

                        headers.push({
                            showJudgeName: false,
                            name: "Judge " + judgeIdNum + " IP (2 Rounds)"
                        });
                        fieldArray.push({
                            roundNum: roundNum,
                            judgeIndex: judgeIndex,
                            name: "judgeFinalIp"
                        })
                        colGroupArray.push("<col bgcolor=\"" + judgeColorsArray[2] + "\">");
                    }
                    break;
                case ROTATION_TYPE.ROTATING: 
                    break;
            }
        }

        let getJudgeName = (roundIndex, judgeIndex) => {
            console.log("judges Array", this.props.events.competition.template["rounds"][roundIndex].judges);
            console.log("roundIndex: ", roundIndex);
            console.log("judgeIndex: ", judgeIndex);
            let firstName = this.props.events.competition.template["rounds"][roundIndex].judges[judgeIndex].name;
            return firstName ? firstName + " " + this.props.events.competition.template["rounds"][roundIndex].judges[judgeIndex].lname
            : "";
        }

        // Build the header and body arrays based on judge rotation type
        let currJudgeIdNum = 0;
        let roundJudgeCount;
        let columnColorIndex = 0;
        switch (panelType) {
            case ROTATION_TYPE.SET: 
                roundJudgeCount = judgeCountArray[0];           // Set panel so judge count is the same for every round
                for(let x = 1; x <= roundJudgeCount; x++) {
                    let currJudgeName = getJudgeName(1, x - 1);
                    currJudgeIdNum++; 
                    addJudgeHeaders(currJudgeName, 1, rounds, currJudgeIdNum, x - 1);
                }

                // TODO: Make IP configurable for the grade
                // Don't show IP if only one judge
                if (judgeCountArray[0] > 1) {                   // It's a SET panel so we can just grab the judge count for round 1
                    headers.push({
                        showJudgeName: false,
                        name: "Total IP"
                    });
                    fieldArray.push({
                        roundNum: 1,
                        judgeIndex: 0,
                        name: "finalIp"
                    })
                    colGroupArray.push("<col bgcolor=\"" + FINAL_COLUMN_COLORS[columnColorIndex % FINAL_COLUMN_COLORS.length] + "\">");
                    columnColorIndex++;
                }

                headers.push({
                    showJudgeName: false,
                    name: "Rank"
                });
                fieldArray.push({
                    roundNum: 1,
                    judgeIndex: 0,
                    name: "finalRank",
                    tiedField: "tied"
                })
                colGroupArray.push("<col bgcolor=\"" + FINAL_COLUMN_COLORS[columnColorIndex % FINAL_COLUMN_COLORS.length] + "\">");
                columnColorIndex++;
                break;

            case ROTATION_TYPE.SPLIT: 
                if (final) {
                    currJudgeIdNum = judgeCountArray[0];                    // Start with however many judges there were for R1/2
                    roundJudgeCount = judgeCountArray[2];                   // Judge count for Round 3
                    for(let x = 1; x <= roundJudgeCount; x++) {
                        let currJudgeName = getJudgeName(3, x - 1);
                        currJudgeIdNum++; 
                        addJudgeHeaders(currJudgeName, 3, 3, currJudgeIdNum, x - 1);
                    }

                    // Total fields for 'final' overview screen
                    headers.push({
                        showJudgeName: false,
                        name: "IP R1 & R2"
                    });
                    fieldArray.push({
                        roundNum: 3,
                        judgeIndex: 0,
                        name: "finalTwoRoundIp"
                    })
                    colGroupArray.push("<col bgcolor=\"" + FINAL_COLUMN_COLORS[columnColorIndex % FINAL_COLUMN_COLORS.length] + "\">");
                    columnColorIndex++;

                    headers.push({
                        showJudgeName: false,
                        name: "IP R3"
                    });
                    fieldArray.push({
                        roundNum: 3,
                        judgeIndex: 0,
                        name: "totalRound3Ip"
                    })
                    colGroupArray.push("<col bgcolor=\"" + FINAL_COLUMN_COLORS[columnColorIndex % FINAL_COLUMN_COLORS.length] + "\">");
                    columnColorIndex++;

                    headers.push({
                        showJudgeName: false,
                        name: "Final IP"
                    });
                    fieldArray.push({
                        roundNum: 3,
                        judgeIndex: 0,
                        name: "finalIp"
                    })
                    colGroupArray.push("<col bgcolor=\"" + FINAL_COLUMN_COLORS[columnColorIndex % FINAL_COLUMN_COLORS.length] + "\">");
                    columnColorIndex++;

                    headers.push({
                        showJudgeName: false,
                        name: "Rank"
                    });
                    fieldArray.push({
                        roundNum: 3,
                        judgeIndex: 0,
                        name: "finalRank",
                        tiedField: "tied"
                    })
                    colGroupArray.push("<col bgcolor=\"" + FINAL_COLUMN_COLORS[columnColorIndex % FINAL_COLUMN_COLORS.length] + "\">");
                    columnColorIndex++;

                } else {
                    roundJudgeCount = judgeCountArray[0];                   // Same for rounds 1 and 2
                    for(let x = 1; x <= roundJudgeCount; x++) {
                        let currJudgeName = getJudgeName(1, x - 1);
                        currJudgeIdNum++; 
                        addJudgeHeaders(currJudgeName, 1, 2, currJudgeIdNum, x - 1);
                    }

                    // Total fields for R1 & R2 overview screen
                    headers.push({
                        showJudgeName: false,
                        name: "Final 2 Round IP"
                    });
                    fieldArray.push({
                        roundNum: 1,
                        judgeIndex: 0,
                        name: "finalTwoRoundIp"
                    })
                    colGroupArray.push("<col bgcolor=\"" + FINAL_COLUMN_COLORS[columnColorIndex] + "\">");
                    columnColorIndex++;

                    headers.push({
                        showJudgeName: false,
                        name: "Final 2 Round Rank"
                    });
                    fieldArray.push({
                        roundNum: 1,
                        judgeIndex: 0,
                        name: "totalTwoRoundRank",
                        tiedField: "final2RoundTied"
                    })
                    colGroupArray.push("<col bgcolor=\"" + FINAL_COLUMN_COLORS[columnColorIndex] + "\">");
                    columnColorIndex++;

                    if (this.props.events.competition.competition.grade.recallType !== RECALL_TYPE.NONE) {
                        headers.push({
                            showJudgeName: false,
                            name: "Recall Y/N"
                        });
                        fieldArray.push({
                            isCheckbox: true,
                            roundNum: 1,
                            judgeIndex: 0,
                            name: "recall"
                        })
                        colGroupArray.push("<col bgcolor=\"" + FINAL_COLUMN_COLORS[columnColorIndex] + "\">");
                        columnColorIndex++;
                    }
                }
                break;
                
            case ROTATION_TYPE.ROTATING: 
                break;

        }

        // Add final columns, unless this is the R1 + R2 overview for SPLIT
        if (final || panelType !== ROTATION_TYPE.SPLIT) {
            // Recall
            if (this.props.events.competition.competition.grade.recallType !== RECALL_TYPE.NONE) {
                headers.push({
                    showJudgeName: false,
                    name: "Recall Y/N"
                });
                fieldArray.push({
                    isCheckbox: true,
                    roundNum: 3,
                    judgeIndex: 0,
                    name: "recall"
                })
                colGroupArray.push("<col bgcolor=\"" + FINAL_COLUMN_COLORS[columnColorIndex % FINAL_COLUMN_COLORS.length] + "\">");
                columnColorIndex++;
            }

            // Placed
            headers.push({
                showJudgeName: false,
                name: "Placed Y/N"
            });
            fieldArray.push({
                isCheckbox: true,
                roundNum: 3,
                judgeIndex: 0,
                name: "placed"
            })
            colGroupArray.push("<col bgcolor=\"" + FINAL_COLUMN_COLORS[columnColorIndex % FINAL_COLUMN_COLORS.length] + "\">");
                columnColorIndex++;

            // NQ
            if (this.props.events.competition.competition.grade.nationalQualifier) {
                headers.push({
                    showJudgeName: false,
                    name: "NQ Y/N"
                });
                fieldArray.push({
                    isCheckbox: true,
                    roundNum: 1,
                    judgeIndex: 0,
                    name: "nq"
                })
                colGroupArray.push("<col bgcolor=\"" + FINAL_COLUMN_COLORS[columnColorIndex % FINAL_COLUMN_COLORS.length] + "\">");
                columnColorIndex++;
            }

            // WQ
            if (this.props.events.competition.competition.grade.worldQualifier) {
                headers.push({
                    showJudgeName: false,
                    name: "WQ Y/N"
                });
                fieldArray.push({
                    isCheckbox: true,
                    roundNum: 1,
                    judgeIndex: 0,
                    name: "wq"
                })
                colGroupArray.push("<col bgcolor=\"" + FINAL_COLUMN_COLORS[columnColorIndex % FINAL_COLUMN_COLORS.length] + "\">");
                columnColorIndex++;
            }
        }
        


        let entries = [];
        let scores = JSON.parse(JSON.stringify(this.props.events.competition.template.rounds["1"].scores[0]));
        if (rounds === 1) {
            if (judgeCount === 1) {
                scores = scores.sort((a, b) => b.round1Score - a.round1Score);
            } else {
                scores = scores.sort((a, b) => b.finalIp - a.finalIp);
            }
        } else {
            if (!final && panelType === 'SPLIT') {
                scores = scores.sort((a, b) => b.finalTwoRoundIp - a.finalTwoRoundIp);
            } else {
                scores = scores.sort((a, b) => a.finalRank - b.finalRank);
            }
        }
        console.log("scores", scores);
        scores.forEach(item => {
            let entry = entriesMain.filter(it => it._id === item.entryCompetition)[0];
            entries.push(entry);
        })

        console.log("ENT", entries);
        let pageTitle = panelType !== 'SPLIT' ? 'Overview' :
            final ? 'Final Overview' : 'Recall Overview'

        return (
            <PageLoader loaded={!this.props.events.loading}>
                <Formik
                    validationSchema={schema}
                    onSubmit={this.handleSubmit}
                    enableReinitialize
                    initialValues={{
                        template: this.props.events.competition.template
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                        isSubmitting,
                        setFieldValue
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>

                            <div>
                                <div className={"tabulator-page-header"}>
                                <Row>
                                    <Col>
                                        <h2>{pageTitle}</h2>
                                        <h4>
                                            {this.props.events.competition ?
                                                this.props.events.competition.competition ?
                                                    this.props.events.competition.competition.grade ?
                                                        this.props.events.competition.competition.grade.rounds === 1 ||
                                                        this.props.events.competition.competition.grade.rounds === 2 ?
                                                            <span>
                                                            <a href={'/app/pdfresults/false'} target="_blank" rel="noopener noreferrer" >Full Results</a> |&nbsp;
                                                            <a href={'/app/pdfoneroundsimple/4/1'} target="_blank" rel="noopener noreferrer" >Print Wall Results</a> |&nbsp;
                                                            <a href={`/app/pdfround3placing/true`} target="_blank" rel="noopener noreferrer">Podium Results</a>
                                                            {/* <a href={`/app/pdfround3placing/true`} target="_blank" rel="noopener noreferrer">Podium Results</a> |&nbsp;
                                                            <a href={'/app/pdfrecall'} target="_blank" rel="noopener noreferrer" >Award Callback</a> */}
                                                        </span>

                                                            : 
                                                        <span>
                                                            ({final ?
                                                                <span>
                                                                    <a href={`/app/pdfresults/true`} target="_blank" rel="noopener noreferrer">Full Results</a>|&nbsp;
                                                                    <a href={`/app/pdfround3placing/true`} target="_blank" rel="noopener noreferrer">Podium Results</a>
                                                                </span>
                                                                : 
                                                                <span>
                                                                    <a href={`/app/pdfresults/false`} target="_blank" rel="noopener noreferrer">Full Results</a>|&nbsp;
                                                                    <a href={`/app/pdfround3placing/true`} target="_blank" rel="noopener noreferrer">Podium Results</a>|&nbsp;
                                                                </span>
                                                            }
                                                            {/* |&nbsp; */}
                                                                {/*<a href={`/app/pdfallresults`} target="_blank" rel="noopener noreferrer">Print Wall Results</a> |&nbsp;*/}
                                                            {/* <a href={`/app/pdfround3placing/true`} target="_blank" rel="noopener noreferrer">Podium Results</a>|&nbsp;
                                                            <a href={`/app/pdfround3placing/false`} target="_blank" rel="noopener noreferrer">Hon Mention</a>|&nbsp; */}
                                                            {this.props.events.competition.competition.grade.recallType !== "NONE" && rounds > 1 ?
                                                            <a href={`/app/pdfrecall`} target="_blank" rel="noopener noreferrer" >Recall Printout</a>
                                                            : null})
                                                            </span>
                                                        : null
                                                    : null
                                                : null}
                                        </h4>

                                    </Col>
                                    <Col>
                                        <div className={"text-right ex-pad"}>
                                            <Button
                                                variant={"primary"}
                                                disabled={this.props.events.competition.competition.generatingResults}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.props.generateCompetitionResults(this.props.match.params.id, this.props.match.params.competition, this.props.auth.token._id);
                                                }}

                                            >{this.props.events.competition.competition.generatingResults ? 'Calculating...' : this.props.events.competition.competition.hasPublicResults ? 'Regenerate Results' :
                                                'Calculate Results'}</Button><br /><br />
                                          {/*  <Button
                                                variant={"primary"}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.props.deleteCompetitionResults(this.props.match.params.competition, this.props.auth.token._id);
                                                }}
                                            >{'Delete Results'}</Button><br /><br /> */}
                                            {this.props.events.competition.competition.hasPublicResults ?
                                                <Button variant={"link"} href={`/public/results/event/${this.props.match.params.id}/${this.props.match.params.competition}`} target={"_blank"}>View <OpenOutline /> </Button>
                                                : null}
                                        </div>
                                    </Col>
                                </Row>
                                </div>


                                <Row className={"tabulator-table-pad"}>
                                    <Col className={"no-padding"} key={"entry"} style={{ overflowX: "scroll" }}>
                                        <Table striped bordered hover>

                                            <OverviewTableColgroup columnStyles={colGroupArray} />
                                            <OverviewTableHeader heading={headers} />
                                            <OverviewTableBody data={entries} fields={fieldArray} 
                                                template = {this.props.events.competition.template} locked={isLocked} 
                                                updateEntryScore={this.props.updateEntryScore} />

                                        </Table>
                                    </Col>
                                </Row>
                                <div className={"text-right ex-pad"}>
                                    <Button variant={"primary"} onClick={e => {
                                        e.preventDefault();
                                        this.props.updateTabScore(
                                            this.props.events.competition.template, 
                                            this.props.events.competition.tabulation, 
                                            this.props.auth.token._id, 
                                            false,
                                            this.props.events.competition.competition._id,
                                            this.props.events.competition.competition.grade.rounds);
                                    }}><Checkmark />{this.props.events.competition.competition.saving ? 'Saving...' :  'Save'}</Button>
                                </div>

                            </div>
                        </Form>
                    )}
                </Formik>
            </PageLoader>

        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user,
    events: state.events,
    single: state.single,
    tabulator: state.tabulator
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
    updateTabScore: (template, tabulation, token, autosave, competitionId, rounds) => {
        dispatch(eventActions.updateTabulatorScore(template, tabulation, token, autosave, competitionId, rounds));
    },
    updateScore: (entry, value, judgeIndex, round) => {
        dispatch(eventActions.updateScore(entry, value, judgeIndex, round));
    },
    updateJudge: (round, judge, val) => {
        dispatch(eventActions.updateJudge(round, judge, val));
    },
    updateJudgeLabel: (round, judge, val) => {
        dispatch(eventActions.updateJudgeLabel(round, judge, val));
    },
    updateTabulationObject: (tab, token) => {
        dispatch(eventActions.updateTabulationObject(tab, token));
    },
    updateEntryScore: (entry, round, key, val) => {
        dispatch(eventActions.updateEntryScore(entry, round, key, val));
    },
    generateCompetitionResults: (eventId, competitionId, token) => {
        dispatch(eventActions.generateCompetitionResults(eventId, competitionId, token));
    },
    deleteCompetitionResults: (competitionId, token) => {
        dispatch(eventActions.deleteCompetitionResults(competitionId, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(TabulatorOverviewTable));

class TabulatorOverviewTableContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

class OverviewTableHeader extends React.Component {

    render() {

        let heading = this.props.heading;

        return (
            <thead>
                <tr>
                    {heading.map((headerItem, index) => (
                        <>
                            {headerItem.showJudgeName ?
                                <th><span className="tabulator-big-text">Judge {headerItem.judgeIdNum}<br /></span>{headerItem.name}<i className="tabulator-small-text"><hr />{headerItem.judgeName}</i></th>
                                :
                                <th>{headerItem.name}</th>
                            }
                        </>
                    ))}
                </tr>
            </thead>
        )
    }
}

class OverviewTableBody extends React.Component {

    render() {

        let data = this.props.data;
        let fields = this.props.fields;
        let template = this.props.template;
        let isLocked = this.props.locked;

        return (
            <tbody>
                {data.map((comp, entryNumber) => {

                    let r1 = []

                    template.rounds["1"].scores.forEach((item, index) => {
                        item.forEach(en => {
                            if (en.entryCompetition === comp._id) {
                                r1.push(en);
                            }
                        })
                    });

                    let r2 = []
                    template.rounds["2"].scores.forEach((item, index) => {
                        item.forEach(en => {
                            if (en.entryCompetition === comp._id) {
                                r2.push(en);
                            }
                        })
                    });

                    let r3 = [];

                    template.rounds["3"].scores.forEach((item, index) => {
                        item.forEach(en => {
                            if (en.entryCompetition === comp._id) {
                                r3.push(en);
                            }
                        })
                    });

                    let scoresArray = [r1, r2, r3];
                    const dancer = comp.dancer;

                    return (
                        <tr key={comp._id + entryNumber}>
                            <td style={{ textAlign: "center", fontSize: 20 }}>
                                <b>{comp.dancerNumber || 0}</b>
                            </td>
                            <td style={{ borderRight: "3px" }}>
                                {dancer.name} {dancer.lname}
                            </td>
                            <td>
                                {dancer.school ? dancer.school.name : "-"}
                            </td>
                            {fields.map((field, fieldNum) => {
                                let fieldString = field.isCheckbox ?
                                    // For a checkbox, the 'fieldString' will be true or false
                                    scoresArray[0][0][field.name] ? true : false             
                                    : 
                                    scoresArray[field.roundNum - 1][field.judgeIndex][field.name] > -1 ? 
                                    scoresArray[field.roundNum - 1][field.judgeIndex][field.name] :
                                    "";
                                if (field.tiedField && scoresArray[field.roundNum - 1][field.judgeIndex][field.tiedField]) {
                                    // If a 'tied' indicator field was supplied and the value for this record is true, add a T after the rank
                                    fieldString += "T";
                                }
                                return (
                                    <>
                                        {field.isCheckbox ?
                                            <td><FormControl
                                                disabled={isLocked}
                                                type="checkbox"
                                                name={field.name}
                                                checked={fieldString}
                                                onChange={e => {
                                                    this.props.updateEntryScore(scoresArray[0][0], 3, field.name, !fieldString)
                                                }}
                                                className={"no-width float-right"}
                                            /></td>
                                            :
                                            <td>{fieldString}</td>
                                        }
                                    </>
                                )
                            })}
                        </tr>
                    );
                })}
            </tbody>
        )
    }
}

class OverviewTableColgroup extends React.Component {

    render() {

        let columnStyles = this.props.columnStyles;
        let colGroupString = columnStyles.join('');

        return(<colgroup dangerouslySetInnerHTML={{ __html: colGroupString }}/>);
    }
}

export default TabulatorOverviewTableContainer;
